"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var mapping = {
    displayName: "Facebook Pixel",
    comment: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["This templates converts incoming events to", " ", (0, jsx_runtime_1.jsx)("a", __assign({ target: "_blank", href: "https://developers.facebook.com/docs/marketing-api/conversions-api/" }, { children: "Facebook conversion (pixel)" })), " ", "API calls. Make sure you use Facebook destination, otherwise configuration won't make much sense"] })),
    keepUnmappedFields: false,
    mappings: [
        {
            src: "/event_type",
            dst: "/event_name",
            action: "move",
        },
        {
            src: "/eventn_ctx/event_id",
            dst: "/event_id",
            action: "move",
        },
        {
            src: "/eventn_ctx_event_id",
            dst: "/event_id",
            action: "move",
        },
        {
            src: "/eventn_ctx/url",
            dst: "/event_source_url",
            action: "move",
        },
        {
            src: "/url",
            dst: "/event_source_url",
            action: "move",
        },
        {
            src: "/source_ip",
            dst: "/user_data/client_ip_address",
            action: "move",
        },
        {
            src: "/eventn_ctx/user/email",
            dst: "/user_data/em",
            action: "move",
        },
        {
            src: "/user/email",
            dst: "/user_data/em",
            action: "move",
        },
        {
            src: "/eventn_ctx/user_agent",
            dst: "/user_data/client_user_agent",
            action: "move",
        },
        {
            src: "/user_agent",
            dst: "/user_data/client_user_agent",
            action: "move",
        },
    ],
};
exports.default = mapping;
